import React, {useCallback, useState, useEffect} from 'react';
import { useTable, usePagination } from 'react-table';
import {AppProvider, Heading, Page, Stack, Card, DataTable, Link, Thumbnail, Icon, VisuallyHidden, ActionList, Frame, TopBar, Pagination} from '@shopify/polaris';
import {TextField, ChoiceList, Filters} from '@shopify/polaris';
import {ArrowLeftMinor, QuestionMarkMajor} from '@shopify/polaris-icons';
import debounce from "lodash/debounce";

const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
      const handler = setTimeout(() => effect(), delay);

      return () => clearTimeout(handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps || [], delay]);
}

const getIndustries = json => {
  let tags = [];
  json['filters']['industry'].map(row => tags.push({label: row, value: row}));
  return tags;
}

const getLocations = json => {
  let tags = [];
  json['filters']['location'].map(row => tags.push({label: row, value: row}));
  return tags;
}

const renderPages = (json, pageLimit) => {
  return <p>Companies {json['offset'] + 1}-{Math.min(json['offset'] + pageLimit, json['total_rows'])} ({json['total_rows']} total)</p>
}

const renderRows = json => {
  let lookup = {}
  for (var i = 0; i < json['columns'].length; i++) {
    lookup[json['columns'][i]] = i;
  }

  return json['data'].map(row => [
    <p style={{width: '230px'}}>
      <Link url={row[lookup['website']]} external>
        {row[lookup['company_name']]}
      </Link>
      <p>{row[lookup['location']]}</p>
      <p style={{marginTop: '6px'}}>
        <Stack >
          <Link url={row[lookup['crunchbase_url']]} external>
            <img src="https://logo.clearbit.com/crunchbase.com" style={{ width: '20px', borderRadius: '3px', filter: 'grayscale(100%)'}} alt='Crunchbase'/>
          </Link>
          <Link url={row[lookup['glassdoor_url']]} external>
            <img src="https://logo.clearbit.com/glassdoor.com" style={{ width: '20px', borderRadius: '3px', filter: 'grayscale(100%)' }} alt='Glassdoor'/>
          </Link>
          <Link url={row[lookup['linkedin_url']]} external>
            <img src="https://logo.clearbit.com/linkedin.com" style={{ width: '20px', borderRadius: '3px', filter: 'grayscale(100%)' }} alt='Linkedin'/>
          </Link>
          <Link url={row[lookup['craft_url']]} external>
            <img src="https://logo.clearbit.com/craft.co" style={{ width: '20px', borderRadius: '3px', filter: 'grayscale(100%)' }} alt='Craft'/>
          </Link>
          <Link url={row[lookup['zoominfo_url']]} external>
            <img src="https://logo.clearbit.com/zoominfo.com" style={{ width: '20px', borderRadius: '3px', filter: 'grayscale(100%)' }} alt='Zoominfo'/>
          </Link>
            <Link style={{ float: 'left' }} url={row[lookup['twitter_url']]} external>
            <img src="https://logo.clearbit.com/twitter.com" style={{ width: '20px', borderRadius: '3px', filter: 'grayscale(100%)' }} alt='Twitter'/>
          </Link>
        </Stack>
      </p>
    </p>,
    <p style={{ width: '260px', whiteSpace: 'normal'}}>{row[lookup['description']].slice(0,100)+'...'}</p>,
    <p style={{ width: '100px', whiteSpace: 'normal'}}>{row[lookup['industry']]}</p>,
    <p style={{ width: '100px', whiteSpace: 'normal'}}>{row[lookup['size']]}</p>,
    <p>{row[lookup['annual_revenue']]}</p>,
    row[lookup['founded']],
    row[lookup['glassdoor_rating']],
  ])
}

async function updateTableData (queryData, setTableData) {
  const { order, pageLimit, activeOffset, filterChoices, searchQuery } = queryData
  const sortColumn = [
      'company_name',
      'description',
      'industry',
      'size',
      'annual_revenue',
      'founded',
      'glassdoor_rating',
  ][order.index]

  const search = {
    sortColumn: sortColumn, sortDirection: order.direction, pageLimit, activeOffset, filterChoices, searchQuery
  }

  return fetch(`${process.env.REACT_APP_BACKEND_URL}?search=${encodeURIComponent(JSON.stringify(search))}`)
    .then((response) => response.json())
    .then((json) => {
    let lookup = {}
    for (var i = 0; i < json['columns'].length; i++) {
      lookup[json['columns'][i]] = i;
    }
      setTableData({
        rows: renderRows(json),
        pages: renderPages(json, queryData.pageLimit),
        totalRows: json['total_rows'],
        filters: {
          industries: getIndustries(json),
          locations: getLocations(json),
        },
      })
    })
}

function HardTechMiamiDataTable() {
  const [tableData, setTableData] = useState({
    rows: [],
    pages: null,
    totalRows: 0,
    filters: {
      industries: [],
      locations: [],
    },
  })

  const [queryData, setQueryData] = useState({
    searchQuery: '',
    pageLimit: 6,
    activeOffset: 0,
    order: {
      index: 4,
      direction: 'descending',
    },
    filterChoices: {
      industry: [],
      location: [],
    }
  })

  // Load the table once at the beginning
  useEffect(() => {
    updateTableData(queryData, setTableData)
    // We need to pass an empty array to ensure this only gets called once.
    // We also need tableData and setTableData to fetch the data. This linter
    // error thus needs to be ignored, unless there's a better way to achieve
    // the same goal.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDebouncedEffect(() => {
    updateTableData(queryData, setTableData)
  }, [queryData], 200);

  const sortable = [true, false, true, true, true, true, true, true]

  const handleSort = (index, direction) => {
    setQueryData({ ...queryData, activeOffset: 0, order: { index, direction } })
  }

  const handlePreviousPage = () => {
    let newOffset = queryData.activeOffset - queryData.pageLimit;
    if (newOffset < 0) {
      newOffset = 0;
    }
    setQueryData({ ...queryData, activeOffset: newOffset })
  }

  const handleNextPage = () => {
    setQueryData({ ...queryData, activeOffset: queryData.activeOffset + queryData.pageLimit })
  }

  const handleFiltersQueryChange = useCallback((searchQuery) => {
      setQueryData({ ...queryData, searchQuery })
    }, [queryData]);

  const handleQueryValueRemove = useCallback(() => {
      setQueryData({ ...queryData, searchQuery: '' })
    }, [queryData]);

  const handleIndustryChange = useCallback((value) => {
      setQueryData({ ...queryData, activeOffset: 0, filterChoices: { ...queryData.filterChoices, industry: value } })
    }, [queryData]);

  const handleLocationChange = useCallback((value) => {
      setQueryData({ ...queryData, activeOffset: 0, filterChoices: { ...queryData.filterChoices, location: value } })
    }, [queryData]);

  const handleIndustryRemove = useCallback(() => {
      setQueryData({ ...queryData, activeOffset: 0, filterChoices: { ...queryData.filterChoices, industry: [] } })
    }, [queryData]);

  const handleLocationRemove = useCallback(() => {
      setQueryData({ ...queryData, activeOffset: 0, filterChoices: { ...queryData.filterChoices, location: [] } })
    }, [queryData]);

  const handleFiltersClearAll = useCallback(() => {
      setQueryData({ ...queryData, activeOffset: 0, filterChoices: { industry: [], location: [] } })
    }, [queryData]);

  const filters = [
  {
    key: 'industry',
    label: 'Industry',
    filter: (
      <ChoiceList
        title="Industry"
        titleHidden
        choices={tableData.filters.industries}
        selected={queryData.filterChoices.industry || []}
        onChange={handleIndustryChange}
        allowMultiple
      />
    ),
    shortcut: true,
  },
  { key: 'location',
    label: 'Location',
    filter: (
      <ChoiceList
        title="Location"
        titleHidden
        choices={tableData.filters.locations}
        selected={queryData.filterChoices.location || []}
        onChange={handleLocationChange}
        allowMultiple
      />
    ),
    shortcut: true,
  }
  ];

  const appliedFilters = [];
  if (!isEmpty(queryData.filterChoices.industry)) {
    const key = 'industry';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryData.filterChoices.industry),
      onRemove: handleIndustryRemove,
    });
  }
  if (!isEmpty(queryData.filterChoices.location)) {
    const key = 'location';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, queryData.filterChoices.location),
      onRemove: handleLocationRemove,
    });
  }

  return (
    <Page title="Miami hard tech database" fullWidth>
      <Card>
        <Card.Section>
          <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            queryValue={queryData.searchQuery}
            onClearAll={handleFiltersClearAll}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
            'text',
            'numeric',
            'numeric',
          ]}
          headings={[
            'Company',
            'Description',
            'Industry',
            'Size',
            'Annual revenue',
            'Founded',
            'Rating',
          ]}
          rows={tableData.rows}
          sortable={sortable}
          defaultSortDirection="descending"
          initialSortColumnIndex={4}
          onSort={handleSort}
        />
      </Card>

      <div style={{marginTop : '16px', display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <Pagination
          hasPrevious={queryData.activeOffset > 0}
          onPrevious={handlePreviousPage}
          hasNext={queryData.activeOffset < tableData.totalRows-queryData.pageLimit}
          onNext={handleNextPage}
        />
      </div>
      <div style={{marginTop : '5px', display: 'flex', justifyContent:'center', alignItems:'center'}}>
        {tableData.pages}
      </div>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'industry':
        return value.map((val) => `${val}`).join(', ');
      case 'location':
        return value.map((val) => `${val}`).join(', ');
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}


function App() {
  const theme = {
    logo: {
      width: 50,
      topBarSource:
        'https://avatars.slack-edge.com/2021-05-11/2067897865713_33c54b5940c894c0fb73_88.png',
      url: 'https://www.hardtechmiami.com',
      accessibilityLabel: 'Hard Tech Miami',
    },
  };

  return (
    <AppProvider
      theme={theme}
      i18n={{
        Polaris: {
          Avatar: {
            label: 'Avatar',
            labelWithInitials: 'Avatar with initials {initials}',
          },
          Frame: {skipToContent: 'Skip to content'},
          TopBar: {
            toggleMenuLabel: 'Toggle menu',
            SearchField: {
              clearButtonLabel: 'Clear',
              search: 'Search',
            },
          },
        },
      }}
    >
      <Frame topBar={<TopBar/>}>
        {HardTechMiamiDataTable()}
      </Frame>
    </AppProvider>
  )
}

export default App
